body {
  margin: 0px;
}

@font-face {
  font-family: Rock;
  src: url('./assets/fonts/rock-sans-normal.otf');
}

@font-face {
  font-family: Rock;
  src: url('./assets/fonts/rock-sans-bold.otf');
  font-weight: bold;
}

@font-face {
  font-family: 'Rock Serif';
  src: url('./assets/fonts/rock-serif-normal.otf');
}

@font-face {
  font-family: 'Rock Serif';
  src: url('./assets/fonts/rock-serif-normal.otf');
  font-weight: bold;
}

.text-unsupported {
  display: flex;
  justify-content: center;
}

.text-unsupported .content {
  display: flex;
  padding: 5px;
  align-items: center;
}
.text-unsupported:not(.unsupported-browser-banner) .content {
  background-color: #f5cd3c;
  padding: 30px;
  width: 100%;
  
}
.text-unsupported h2 {
  margin: 10px 0;
  color: #303B42;
  font-family: Rock;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 34px;
  font-weight: 500;
  white-space: pre-wrap;
  width: 100%;
}
.text-unsupported .close-btn {
  margin-top: 2px;
  align-items: center;
}

:root {
  --mobile-media: 600px;
}
