.header {
  display: flex;
  margin-bottom: 55px;
  align-items: flex-end;

  flex-wrap: wrap;
}
.header > div {
  margin-bottom: 10px;
}
.header a:hover {
  color: #303b42;
}
.header a {
  font-family: Rock;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.11px;
  line-height: 22px;
  text-decoration: none;
}
.header > *:not(:last-child) {
  margin-right: 50px;
}

.headerLabel {
  color: #303b42;
  font-family: Rock;
  font-size: 25px;
  font-weight: 100;
}

.description {
  color: #6f7680;
  margin-bottom: 50px;
  font-family: Rock;
  font-size: 18px;
  font-weight: 300 !important;
}

.description span {
  display: block;
}

.activeNav,
.inactiveNav {
  padding-bottom: 8px;
  background: linear-gradient(to right, #abf8fc 0%, #01c7ee 100%) left bottom
    no-repeat;
}

@keyframes background-reset {
  0% {
    background-size: 100px 8px;
  }
  100% {
    background-size: 50px 8px;
  }
}

.activeNav {
  background-size: 50px 8px;
  animation: background-reset 0.8s forwards;
}

.inactiveNav {
  background-size: 0px 8px;
}

@keyframes resize-background {
  100% {
    background-size: 100px 8px;
  }
}

.activeNav:hover,
.inactiveNav:hover {
  animation: resize-background 0.8s forwards;
}

.navLabel {
  font-size: 18px;
}

.navActive > a {
  color: #303b42;
}
.navDefault > a {
  color: #6f7680;
}
.labelExta {
  margin-left: 10px;
  letter-spacing: 0.11px;
  font-weight: normal;
  line-height: 22px;
}
