.description {
    color: #6f7680;
    margin-bottom: 50px;
    font-family: Rock;
    font-size: 18px;
    font-weight: 300 !important;
}
  
.description span {
    display: block;
}

.headerLabel {
    color: #303b42;
    font-family: Rock;
    font-size: 25px;
    font-weight: 100;
    margin-top: 50px;
}

