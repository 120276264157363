.cardStyles {
    width: 100%;
    padding: 20px;
    color: rgb(102, 102, 102) !important;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30vh;
    font-family: Arial, Helvetica, sans-serif;  
    text-align: center;
}

.actionGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
    color: rebeccapurple;

    margin-top: 20px;
}

.actionGroup *:nth-child(1) {
    font-weight: bold;
}

.actionBtn {
    cursor: pointer;
}

.actionBtn:hover {
    color: rgb(78, 31, 126);
}

.actionGroup *:nth-child(odd) {
    /* 
        this makes the assumption that each item 
        will be divider separated  
    */
    padding: 10px;
}

*:focus {
    outline: none;
}

@media (min-width: 600px) {
    .cardStyles {
        max-width: 500px;
    }
}