.footer {
  display: flex;
  bottom: 0;
  width: 100%;
}

.textGroup {
  background: linear-gradient(52.51deg, #20014b 0%, #400095 100%);
  padding-left: 5%;
  width: 100%;
  height: 180px;
  padding-top: 45px;
}

.textGroupDev {
  background: linear-gradient(52.51deg, #0f5d55 0%, #0e9285 100%);
  padding-left: 5%;
  width: 100%;
  height: 180px;
  padding-top: 45px;
}

.logoText {
  color: #ffffff;
  font-family: 'Rock Serif';
  font-size: 18.2px;
  letter-spacing: 0;
  line-height: 22px;
  margin: 0px;
}

.appName {
  margin: 0px;
  color: #ffffff;
  font-family: Rock;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 47.5px;
}

.copyright {
  height: 21px;
  opacity: 0.49;
  color: #d5d5d5;
  font-family: Helvetica;
  font-size: 14px;
  letter-spacing: 1.17px;
  line-height: 21px;
}
