.submitButton,
.submitButtonDisabled {
  border-radius: 50px !important;
  width: 190px;
  height: 54px;
  padding: 17px 17px 17px 27px;
  text-transform: uppercase;
  box-shadow: none;
}
.submitButtonDisabled {
  background-color: #818181 !important;
}
.submitButton {
  background-color: #5f1fae !important;
}
