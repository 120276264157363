.submissionReviewToolbar {
    display: flex;
    justify-content: space-between;
}

.submissionReviewToolbar h1 {
    font-size: 24px;
}

.submissionCell {
    display: flex !important;
    align-items: center;
}
.submissionCell svg {
    margin-right: 8px;
}
.submissionReviewer {
    height: 400px;
    max-height: 400px;
}