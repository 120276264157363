.headerContainer {
  height: 300px;
  width: 100%;
  background: linear-gradient(44.56deg, #20014b 0%, #400095 100%);
  position: relative;
  margin-top: -25px;
}

.headerContainerDev {
  height: 300px;
  width: 100%;
  background: linear-gradient(44.56deg, #0f5d55 0%, #0e9285 100%);
  position: relative;
  margin-top: -25px;
}

.logoText {
  color: #ffffff;
  font-family: 'Rock Serif';
  font-weight: bold;
  font-size: 23px;
  letter-spacing: 0;

  padding-top: 35px;
  margin-left: 5%;
}

.textBackground {
  height: 222px;
  width: 90%;
  background: linear-gradient(60deg, #20014b 0%, #400095 100%);
  position: absolute;
  bottom: 0;
  min-width: fit-content;
  z-index: 0;
}

.textBackgroundDev {
  height: 222px;
  width: 90%;
  background: linear-gradient(60deg, #0f5d55 0%, #0e9285 100%);
  position: absolute;
  bottom: 0;

  min-width: fit-content;
  z-index: 0;
}

.headerText {
  margin-bottom: 0px;
  padding-bottom: 0px;
  color: #ffffff;
  font-family: Rock;
  font-size: 55px;
  letter-spacing: 0;
  line-height: 95px;

  margin-left: 5%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dots {
  background-image: url(../../../dots2.png);
  width: 45%;
  position: absolute;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.dotGroup {
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
}

.navActive,
.navDefault {
  color: #b9d0d2;
  font-family: Rock;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: 20px;
  text-decoration: none;
}

.nav {
  display: flex;
  flex-direction: row;

  margin-top: 40px;
  justify-content: flex-start;
  margin-left: 5%;
}

.navActive {
  color: #1ad3e5;
}

.profileContainer {
  position: absolute;
  right: 10%;
  top: 20px;
  font-family: sans-serif;
  text-transform: capitalize;

  display: flex;
  align-items: center;
  color: white;
}

.popoverContent {
  width: auto;
  padding: 1rem 2rem;
  font-family: sans-serif;
}

.popoverContent div {
  cursor: pointer;
}

.profileContainer {
  cursor: pointer;
}
.profileContainer span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.profileContainer > svg {
  margin-right: 10px;
}
.downBtn {
  margin-left: 10px;
  color: rgb(172, 172, 172);
}

.headerContainer span, .headerContainerDev span {
  vertical-align: super;
  margin-left: 5px;
}

.tags {
  color: white;
  text-decoration: none;
}
.submission-reviewer-toolbar {
  display: flex;
  justify-content: space-between;
}

.release {
  font-size: 18px;
}

.releaseNotes {
  white-space: pre-wrap;
}

.headerNavButtons {
  margin-top: 40px !important;
}