.dropzone > div {
  border-style: solid;
  border-color: rgb(148, 148, 148);
  border-width: 1px;
}

.dropzone {
  margin-bottom: 50px;
}

.dropzone svg {
  display: none;
}

.dropzone button svg {
  display: flex;
}

.dropzoneBody {
  font-family: Rock;
  font-size: 18px;
  margin-top: 90px;
  color: rgb(116, 116, 116);
}

.downloadTemplateBtn {
  display: flex;
  align-items: center;
  text-decoration: none;
  width: fit-content;
  color: rgb(126, 126, 126);
}

.downloadTemplateBtn p {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  font-family: Rock;
  letter-spacing: 0.91px;
  line-height: 34px;
}

.downloadIcon {
  font-size: 20px;
  margin-left: 10px;
}

.uploadBtn {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(148, 148, 148);
  font-size: 13px !important;

  padding: 0;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;

  text-transform: uppercase;

  margin-left: 10px;
  padding: 15px;
  border-radius: 700px;
  width: 100px;
}

.actionGroup {
  margin: 20px;
}
