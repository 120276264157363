.queryDisplay {
  display: inline-block;
  font-weight: bold;
  font-size: 25px;
  font-family: Rock;
  color: #303b42;
  flex: 1;
  line-height: 38px;
}

.resultsSummary {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 60px;
}

.resultsCount {
  color: #6f7680;
  font-family: sans-serif;
}
.status {
  text-transform: capitalize;
}

.table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  font-family: sans-serif;
  font-size: 16px;
}

.statusItem {
  list-style: none;
}

.tableHead {
  border-bottom: 1px solid #d8d8d8;
  color: #303b42;
}
.tableHead th {
  font-size: 16px;
  font-weight: 100;
  /* white-space: nowrap; */

  padding: 0px 0px 23px 16px;
}
.tableBody td {
  padding: 20px 0px 23px 16px;
  color: #303b42;
  font-size: 18px;
  font-weight: 500;
  font-family: sans-serif;
  /* white-space: nowrap; */
}

.tableBody tr:nth-child(even) {
  background-color: #f3f3f3;
}
.tableBody .tdHighlight {
  color: #400095;
}

.thCenter {
  text-align: left;
}

.disabledDate {
  opacity: .5;
}

.tdHighlight .emptyTd {
  /* 50% (aka center align) is actaully off center. */ 
  padding-left: 30%;
}

.inActiveUser {
  opacity: 0.4;
}