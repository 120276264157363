.inputContainer {
    display: flex;
    flex-wrap: wrap;
}

.inputContainer > div {
    width: 100%;
    margin: 10px;
    min-width: 250px;
    max-width: 300px;
}

.inputContainer > *:not(:last-child) {
    margin-right: 30px !important;
}
  
.mainForm {
    margin-bottom: 50px;
}
  
.headerLabel {
    color: #303b42;
    font-family: Rock;
    font-size: 25px;
    font-weight: 100;
    margin-top: 50px;
}
  
.actionGroup {
    margin-top: 20px;
}
  