.main {
  padding: 50px 20% 50px 10%;
  height: fit-content;
  position: relative;
  min-height: 50vh;
}

.mainContainer {
  padding-bottom: 50px;
}
