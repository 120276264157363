.pageTitle {
  color: #303b42;
  font-family: Rock;
  font-weight: normal;
  font-size: 48px;
  letter-spacing: 0.28px;
  line-height: 58px;

  margin-left: 30px;
}

.pageHeader {
  display: flex;
  align-items: center;

  margin-bottom: 25px;
}
.pageDescription {
  color: #6f7680;
  font-family: Rock;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 34px;

  margin-bottom: 50px;
}
.pageDescription > span {
  display: block;
  color: #303b42;
  font-weight: bold;
  display: block;
}
